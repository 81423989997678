<template>
  <div class="page-box">
    <BaseSubTitle title="店铺信息" />
    <a-spin :spinning="formLoading" tip="Loading...">
      <a-form-model v-if='!disabled' style="width:640px;" ref="formRef" :model="formData" @submit.native.prevent :label-col="labelCol" :wrapper-col="wrapperCol" :rules="disabled?{}:rules">
        <a-row class='refuse-cause' v-if="authState === 3">
          <a-col :span='6'>
            <p style='text-align:right'>审核失败：</p>
          </a-col>
          <a-col :offset='6'>
            <p>{{formData.refuseCause}}</p>
          </a-col>
        </a-row>
        <a-form-model-item label="认证类型" prop="authType">
          <a-radio-group v-model="formData.authType" button-style="solid">
            <a-radio-button value="1">
              企业
            </a-radio-button>
            <a-radio-button value="2">
              个人
            </a-radio-button>
            <a-radio-button value="3">
              个体工商户
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="formData.authType !== '2'" label="企业名称：" prop="compName">
          <a-input v-model="formData.compName" allowClear placeholder="请填写" />
          <p class="tips">请填写真实企业信息</p>
        </a-form-model-item>
        <a-form-model-item v-if="formData.authType !== '2'" label="统一社会信用代码：" prop="compCode">
          <a-input v-model="formData.compCode" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item v-if="formData.authType !== '2'" label="统一社会信用代码证：" prop="compCodeImg">
          <picture-card-upload :fileList="formData.compCodeImg?[formData.compCodeImg]:[]" @update:fileList="res=>$set(formData, 'compCodeImg', res.length ? res[0] : '')" :max="1" />
        </a-form-model-item>
        <a-form-model-item :label="formData.authType !== '2'?'法人姓名：':'个人姓名'" prop="legalName">
          <a-input v-model="formData.legalName" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="证件号码：" prop="legalIdcardCode">
          <a-input v-model="formData.legalIdcardCode" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="法人身份证照：" :required="!disabled">
          <a-row>
            <a-col :span="8">
              <a-form-model-item prop="legalIdcardFront">
                <picture-card-upload :fileList="formData.legalIdcardFront?[formData.legalIdcardFront]:[]" @update:fileList="res=>$set(formData, 'legalIdcardFront', res.length ? res[0] : '')" :max="1" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item prop="legalIdcardBack">
                <picture-card-upload :fileList="formData.legalIdcardBack?[formData.legalIdcardBack]:[]" @update:fileList="res=>$set(formData, 'legalIdcardBack', res.length ? res[0] : '')" :max="1" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="手持身份证照片：" prop="legalIdcardHanded">
          <picture-card-upload :fileList="formData.legalIdcardHanded?[formData.legalIdcardHanded]:[]" @update:fileList="res=>$set(formData, 'legalIdcardHanded', res.length ? res[0] : '')" :max="1" />
        </a-form-model-item>
        <a-form-model-item v-if='!disabled || formData.bondAmount' label="保证金凭证" prop="bondAmount">
          <picture-card-upload :fileList="formData.bondAmount?[formData.bondAmount]:[]" @update:fileList="res=>$set(formData, 'bondAmount', res.length ? res[0] : '')" :max="1" />
        </a-form-model-item>
        <a-form-model-item label="联系人：" prop="linkName">
          <a-input v-model="formData.linkName" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="联系方式：" prop="linkPhone">
          <a-input v-model="formData.linkPhone" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="联系邮箱：" prop="linkEmail">
          <a-input v-model="formData.linkEmail" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="注册地址：" prop="compAddr">
          <a-input v-model="formData.compAddr" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="经营范围：" prop="compScope">
          <a-textarea :auto-size="{ minRows: 4, maxRows: 6 }" v-model="formData.compScope" allowClear placeholder="请填写" />
        </a-form-model-item>
        <a-form-model-item label="税率" prop="taxRate">
          <a-select v-model="formData.taxRate" placeholder="请选择">
            <a-select-option v-for="d in taxRates" :key="d.value">{{d.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="营业时间：">
          <a-range-picker v-model="formData.compDateRange" format="YYYY-MM-DD" />
        </a-form-model-item>
        <a-row>
          <a-col :offset='6' :span='18'>
            <a-checkbox v-model='agreementFlag'>
              我已阅读并同意<a href="https://xfxmall.oss-cn-qingdao.aliyuncs.com/agreement/%E8%96%AA%E5%8F%91%E7%8E%B0%E5%95%86%E6%88%B7%E5%85%A5%E9%A9%BB%E5%8D%8F%E8%AE%AE.docx" style='color:#1890ff;' download target="_blank">《薪发现商户入驻协议》</a>
            </a-checkbox>
          </a-col>
        </a-row>

        <a-row :gutter="24" type="flex" align="middle" justify="center" style="margin-top:32px;">
          <a-button style="margin-right:16px;" type="primary" :disabled="!agreementFlag" @click="onSubmit">提交</a-button>
          <a-button>取消</a-button>
        </a-row>
      </a-form-model>
      <a-descriptions v-else title="" layout="vertical" bordered size="small">
        <a-descriptions-item label="审核状态" :span='formData.authState===3?1:3'>
          <span :style="{color:shopAuthStates.find(x=>x.value === formData.authState)?shopAuthStates.find(x=>x.value === formData.authState).color:'#000'}">{{shopAuthStates.find(x=>x.value === formData.authState)?shopAuthStates.find(x=>x.value === formData.authState).name:'-'}}</span>
        </a-descriptions-item>
        <a-descriptions-item :span='2' label="拒绝原因" v-if="formData.authState === 3 && formData.refuseCause"><span class="refuse-cause">{{formData.refuseCause}}</span></a-descriptions-item>
        <a-descriptions-item label="认证类型">{{formData.authType === '1' ? '企业' : formData.authType === '2'?'个人':'个体工商户'}}</a-descriptions-item>
        <a-descriptions-item :label="formData.authType !== '2' ? '法人姓名': '姓名'">{{formData.legalName}}</a-descriptions-item>
        <a-descriptions-item label="证件号码">{{formData.legalIdcardCode}}</a-descriptions-item>
        <template v-if="formData.authType !== '2'">
          <a-descriptions-item label="企业名称" :span="1">{{formData.compName}}</a-descriptions-item>
          <a-descriptions-item label="统一社会信用代码" :span="1">{{formData.compCode}}</a-descriptions-item>
          <a-descriptions-item label="统一社会信用代码证" :span="1">
            <picture-card-upload isPreview :file-list="[formData.compCodeImg]" />
          </a-descriptions-item>
        </template>
        <a-descriptions-item label="保证金凭证" :span="1" v-if='formData.bondAmount'>
          <picture-card-upload isPreview :file-list="[formData.bondAmount]" />
        </a-descriptions-item>
        <a-descriptions-item label="手持身份证照片" :span="1">
          <picture-card-upload isPreview :file-list="[formData.legalIdcardHanded]" />
        </a-descriptions-item>
        <a-descriptions-item label="身份证照片" :span="formData.authType !== '2' ? 1 : 2">
          <picture-card-upload isPreview :file-list="[formData.legalIdcardFront, formData.legalIdcardBack]" />
        </a-descriptions-item>
        <a-descriptions-item label="联系人">{{formData.linkName||'未填写'}}</a-descriptions-item>
        <a-descriptions-item label="联系方式">{{formData.linkPhone||'未填写'}}</a-descriptions-item>
        <a-descriptions-item label="联系邮箱">{{formData.linkEmail||'未填写'}}</a-descriptions-item>
        <a-descriptions-item label="注册地址">{{formData.compAddr||'未填写'}}</a-descriptions-item>
        <a-descriptions-item label="经营范围">{{formData.compScope||'未填写'}}</a-descriptions-item>
        <a-descriptions-item label="税率">{{formData.taxRate !== undefined ? taxRates.find(x=>+x.value === formData.taxRate).name:'-'}}</a-descriptions-item>
        <a-descriptions-item label="营业时间">{{formData.compSdate && formData.compEdate ? `${formData.compSdate} - ${formData.compEdate}` : '未填写'}}</a-descriptions-item>
        <a-descriptions-item label="协议" :span='3'><a href="https://xfxmall.oss-cn-qingdao.aliyuncs.com/agreement/%E8%96%AA%E5%8F%91%E7%8E%B0%E5%95%86%E6%88%B7%E5%85%A5%E9%A9%BB%E5%8D%8F%E8%AE%AE.docx" style='color:#1890ff;' download target="_blank">《薪发现商户入驻协议》</a></a-descriptions-item>
      </a-descriptions>
    </a-spin>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%;margin-top:24px;" :src="baseUrl + previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { common, shop } from '@/api'
import { shopAuthStates, taxRates } from '@/utils/constants'
import { checkIdCard, emailValidate, phoneValidate } from '@/utils/validator'
import moment from 'moment'
export default {
  name: 'ShopAuto',
  setup (props, { root }) {
    const labelCol = { span: 6 }
    const wrapperCol = { span: 18 }
    const rules = {
      authType: [{ required: true, message: '请选择认证类型', trigger: 'change' }],
      compName: [
        { required: true, message: '请输入企业名称', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的企业名称', trigger: 'change' },
      ],
      compCode: [
        {
          required: true,
          message: '请输入统一社会信用代码',
          trigger: 'change',
        },
        {
          max: 32,
          message: '您可以尝试一下简短的统一社会信用代码',
          trigger: 'change',
        },
      ],
      compCodeImg: [
        {
          required: true,
          message: '请上传统一社会信用代码证件照片',
          trigger: 'change',
        },
      ],
      legalIdcardHanded: [
        {
          required: true,
          message: '请上手持身份证照片',
          trigger: 'change',
        },
      ],
      legalName: [
        { required: true, message: '请输入姓名', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的姓名', trigger: 'change' },
      ],
      linkEmail: [
        { required: true, message: '请输入联系邮箱', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的联系邮箱', trigger: 'change' },
        { validator: emailValidate, trigger: 'blur' },
      ],
      legalIdcardCode: [
        { required: true, message: '请输入证件号码', trigger: 'change' },
        { validator: checkIdCard, trigger: 'blur' },
      ],
      legalIdcardBack: [{ required: true, message: '请上传身份证反面照片', trigger: 'change' }],
      legalIdcardFront: [{ required: true, message: '请上传身份证正面照片', trigger: 'change' }],
      linkName: [
        { required: true, message: '请输入联系人姓名', trigger: 'change' },
        { max: 32, message: '您可以尝试一下简短的联系人姓名', trigger: 'change' },
      ],
      linkPhone: [
        { required: true, message: '请输入联系方式', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' },
      ],
      taxRate: [{ required: true, message: '请选择税率', trigger: 'change' }],
    }
    const baseApi = process.env.VUE_APP_API
    const baseUrl = process.env.VUE_APP_IMG
    const state = reactive({
      formRef: null,
      authState: '',
      disabled: false,
      id: getSession(SHOPID),
      formLoading: false,
      previewVisible: false,
      previewImage: '',
      formData: { authType: '1', compDateRange: [] },
      legalIdcardHandedList: [],
      compCodeImgList: [],
      legalIdcardFrontList: [],
      legalIdcardBackList: [],
      agreementFlag: false,
    })
    onMounted(() => {
      getAuthDetail()
    })
    async function getAuthDetail () {
      state.formLoading = true
      let { code, data, msg } = await shop.getShopAuthData({
        shopId: getSession(SHOPID),
      })
      state.formLoading = false
      if (code === '00000') {
        if (data) {
          state.formData = {
            ...data,
            compDateRange:
              data.compSdate && data.compEdate
                ? [moment(data.compSdate, 'YYYY-MM-DD'), moment(data.compEdate, 'YYYY-MM-DD')]
                : [],
          }
          state.authState = data.authState
          state.disabled = data.authState === 1 || data.authState === 2
          state.compCodeImgList = [
            {
              status: 'done',
              url: baseUrl + data.compCodeImg,
              uid: new Date().getTime(),
              name: '1',
            },
          ]
          state.legalIdcardFrontList = [
            {
              status: 'done',
              url: baseUrl + data.legalIdcardFront,
              uid: new Date().getTime(),
              name: '1',
            },
          ]
          state.legalIdcardBackList = [
            {
              status: 'done',
              url: baseUrl + data.legalIdcardBack,
              uid: new Date().getTime(),
              name: '1',
            },
          ]
          state.legalIdcardHandedList = [
            {
              status: 'done',
              url: baseUrl + data.legalIdcardHanded,
              uid: new Date().getTime(),
              name: '1',
            },
          ]
        }
      } else {
        root.$message.error(msg || '网络错误，请重试～')
      }
    }
    function handlePreview (file) {
      state.previewImage = file
      state.previewVisible = true
    }
    function onSubmit () {
      state.formRef.validate(async (valid) => {
        if (valid) {
          let param = {
            ...state.formData,
            compSdate: state.formData.compDateRange.length
              ? moment(state.formData.compDateRange[0]).format('YYYY-MM-DD')
              : '',
            compEdate: state.formData.compDateRange.length
              ? moment(state.formData.compDateRange[1]).format('YYYY-MM-DD')
              : '',
            shopId: getSession(SHOPID),
          }
          let { code, msg } = await shop.saveShopAuth(param)
          if (code === '00000') {
            root.$message.success('提交成功')
            getAuthDetail()
          } else {
            root.$message.error(msg || '提交失败')
          }
        } else {
          return false
        }
      })
    }
    return {
      taxRates,
      common,
      baseApi,
      baseUrl,
      labelCol,
      wrapperCol,
      rules,
      ...toRefs(state),
      onSubmit,
      handlePreview,
      getAuthDetail,
      shopAuthStates,
    }
  },
}
</script>
<style lang="less" scoped>
.refuse-cause {
  color: red;
  margin-bottom: 24px;
}
::v-deep .ant-form-item {
  margin-bottom: 12px;
}
.idcard-box {
  ::v-deep .ant-upload-picture-card-wrapper {
    width: 120px;
  }
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.img {
  width: 104px;
  height: 104px;
  object-fit: cover;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
}
::v-deep .ant-descriptions-item-content {
  max-width: 300px;
}
</style>
